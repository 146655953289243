import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig"; // Import Firestore
import { collection, getDocs } from "firebase/firestore";
import "./ViewLocations.css"; // Import CSS for the component

function ViewLocations() {
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetch all locations from Firestore
    const fetchLocations = async () => {
      const querySnapshot = await getDocs(collection(db, "locations"));
      const locationsArray = [];
      querySnapshot.forEach((doc) => {
        locationsArray.push(doc.data().name);
      });
      setLocations(locationsArray);
    };

    fetchLocations();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredLocations = locations.filter((location) =>
    location.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="view-locations">
      <input
        type="text"
        placeholder="Search for a location..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-input"
      />
      <div className="table-container">
        <table className="locations-table">
          <thead>
            <tr>
              <th>Location Name</th>
            </tr>
          </thead>
          <tbody>
            {filteredLocations.map((location, index) => (
              <tr key={index}>
                <td>{location}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ViewLocations;
