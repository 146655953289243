import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddLocation from "./components/AddLocation";
import RemoveLocation from "./components/RemoveLocation";
import EditLocation from "./components/EditLocation";
import ViewLocations from "./components/ViewLocations"; // Import the new component
import logo from "./logo-HH@2x.png";
import "./App.css";

function App() {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();
  const [selection, setSelection] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const renderComponent = () => {
    switch (selection) {
      case "add":
        return <AddLocation setIsTyping={setIsTyping} />;
      case "remove":
        return <RemoveLocation setIsTyping={setIsTyping} />;
      case "edit":
        return <EditLocation setIsTyping={setIsTyping} />;
      case "view":
        return <ViewLocations />; // Add ViewLocations to the switch
      default:
        return (
          <div className="no-selection-message">
            Please select an action from the dropdown.
          </div>
        ); // Use a CSS class
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <ToastContainer closeButton={false} theme="light" />
      {isAuthenticated ? (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <select
            onChange={(e) => setSelection(e.target.value)}
            value={selection}
          >
            <option value="">Select Action</option>
            <option value="add">Add Location</option>
            <option value="remove">Remove Location</option>
            <option value="edit">Edit Location</option>
            <option value="view">View Locations</option>
          </select>
          {renderComponent()}
          <button
            className="logoutButton"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log Out
          </button>
        </header>
      ) : (
        <div className="loginContainer">
          <button onClick={loginWithRedirect}>Log In</button>
        </div>
      )}
    </div>
  );
}

export default App;
