import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig"; // Import Firestore
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify"; // Import toast for notifications
import "react-toastify/dist/ReactToastify.css";
import "./RemoveLocation.css"; // Import the CSS file

function RemoveLocation({ setIsTyping }) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    // Fetch all locations from Firestore
    const fetchLocations = async () => {
      const querySnapshot = await getDocs(collection(db, "locations"));
      const locationsArray = [];
      querySnapshot.forEach((doc) => {
        locationsArray.push({ id: doc.id, name: doc.data().name });
      });
      setLocations(locationsArray);
    };

    fetchLocations();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsTyping(true);

    if (value) {
      const filteredSuggestions = locations.filter((location) =>
        location.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setInputValue(location.name);
    setSuggestions([]);
  };

  const handleRemoveLocation = async () => {
    if (selectedLocation) {
      try {
        await deleteDoc(doc(db, "locations", selectedLocation.id));
        toast.success("Location removed successfully!");

        // Clear input and state
        setInputValue("");
        setSelectedLocation(null);
        setSuggestions([]);
      } catch (error) {
        console.error("Error removing document: ", error);
        toast.error("Failed to remove location. Please try again.");
      }
    } else {
      toast.error("Please select a location to remove.");
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search for a location..."
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className="suggestions">
        {suggestions.map((suggestion) => (
          <div
            key={suggestion.id}
            className="suggestion-item"
            onClick={() => handleLocationSelect(suggestion)}
          >
            {suggestion.name}
          </div>
        ))}
      </div>
      <button onClick={handleRemoveLocation} className="remove-button">
        Remove Location
      </button>
    </div>
  );
}

export default RemoveLocation;
