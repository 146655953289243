// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBdSwV9haPH9m6r4lx9kyT8nRc51VWYQxk",
  authDomain: "nrvcs-389918.firebaseapp.com",
  projectId: "nrvcs-389918",
  storageBucket: "nrvcs-389918.appspot.com",
  messagingSenderId: "206774960303",
  appId: "1:206774960303:web:a38bedc897c5d0582c145a",
  measurementId: "G-C9WZR3JHB9",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
